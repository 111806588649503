
.banner {
    color:#242424;
    font-weight: 700;
    font-family: "myHelda", Fallback, sans-serif;
    font-size: 1.2em;
    margin-top: 1.5em;
    
  }
  
  .econ {
    width: 1.3em;
    height: 1.3em;
    margin-left: -1.0em;
  }
  
  .welcome-text-div {
    font-family: "myHelda", Fallback, sans-serif;
    margin-top: 2em;
    
  }
  
  .custome-margin-bottom{
  
    margin-bottom: 100px;
  }
  .welcome-text-div-text-1 {
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
  }
  
  .welcome-text-div-text-4 {
    font-size: 0.95em;
    font-weight: 500;
    text-align: center;
  }
  .font-weight-700{
    font-weight: 700;
  }
  .welcome-text-div-text-2 {
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
  }
  .div-go{
    margin-top: .0em;
  }
  
  .welcome-text-div-location{
    font-family: "myHelda", Fallback, sans-serif;
  }
  
  .welcome-text-div-text-3{
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .econ-footer{
    width: 1em;
    height: 1.3em;
  }


  .arrowLeft{
    width: 1.2em;
    height: 1.5em;
    object-fit: contain;
  }

  .arrowLeftInvert{
    width: 1.2em;
    height: 1.5em;
    object-fit: contain;
    filter: invert(100%);
  }

  .colorAppWhite{

    color: #f2f2f2;
  }

  .colorAppBlack{
    color: #242424;
  }
  
  .iconRight{
    width: 1.2em;
    height: 1.5em;
    text-align: right;
    object-fit: contain;
  }

  .iconRightInvert{
    width: 1.2em;
    height: 1.5em;
    object-fit: contain;
    filter: invert(100%);
  }

  .marginLeft{
    margin-left: .5em;
  }

  .marginRight{
    margin-right: .5em;
  }
.btn-border-less{
  border-radius: 0;
  font-size:1.1em ;
  border: 0;
  cursor: pointer;
  outline: none;
}


.btn-border-less-2{
  border-radius: 0;
  font-size:1.1em ;
  border: 0;
  cursor: pointer;
  outline: none;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
}
