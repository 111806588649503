.custome-margin-bottom {
  margin-bottom: 100px;
}

.fontWeight700 {
  font-weight: 700;
}

.econ-footer {
  width: 1em;
  height: 1.3em;
}

.welcome-text-div {
  font-family: "myHelda", Fallback, sans-serif;
  margin-top: 2em;
}

.custome-margin-bottom {
  margin-bottom: 100px;
}
.welcome-text-div-text-1 {
  font-size: 1.1em !important;
  font-weight: 500;
  text-align: center;
}

.welcome-text-div-text-4 {
  font-size: 0.95em !important;
  font-weight: 500;
  text-align: center;
}
.font-weight-700 {
  font-weight: 700;
}
.welcome-text-div-text-2 {
  font-size: 1.2em !important;
  font-weight: 700 !important;
  text-align: center;
}
.div-go {
  margin-top: 0em;
}

.welcome-text-div-location {
  font-family: "myHelda", Fallback, sans-serif;
}

.welcome-text-div-text-3 {
  font-size: 1.1em !important;
  font-weight: 500;
  text-align: center;
}
.econ-footer {
  width: 1em;
  height: 1.3em;
}

.iconProfile {
  width: inherit;
  height: 1em;
  object-fit: contain;
}

.iconProfileSmall {
  width: inherit;
  height: 1.9em;
  margin-right: 0.3em;
  object-fit: contain;
}

.appfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #242424;
  font-size: 0.95em;
  padding: 0.25em;

  font-family: "myHelda", Fallback, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.dropdown {
  width: 80% !important;
}
.light kbd {
  font-family: "Ericsson Hilda Mono", Consolas, Menlo;
  color: #242424;
  padding: 2px 5px;
  font-size: 12px;
  margin: 0;
  background: #e6e6e6;
  border-radius: 3px;
}

.dark kbd {
  font-family: "Ericsson Hilda Mono", Consolas, Menlo;
  color: #f2f2f2;
  padding: 2px 5px;
  font-size: 12px;
  margin: 0;
  background: #444;
  border-radius: 3px;
}

.example .select {
  width: 100%;
  margin: 8px 0;
}

.user {
  vertical-align: middle;
}

.rule-do,
.rule-dont {
  display: inline-block;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 16px;
}

.light .rule-do {
  border-bottom: 3px solid #329864;
}

.dark .rule-do {
  border-bottom: 3px solid #288964;
}

.light .rule-dont {
  border-bottom: 3px solid #ed0e00;
}

.dark .rule-dont {
  border-bottom: 3px solid #dc2d37;
}

.required-field::before {
  content: "*";
  color: red;
}

.approval-box {
  margin: 20px 0 0 18px;
}

@font-face {
  font-family: "myHelda";
  src: url("./app/fonts/Hilda-Regular.eot");
  /* IE9 Compat Modes */
  src: url("./app/fonts/Hilda-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./app/fonts/Hilda-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./app/fonts/Hilda-Regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./app/fonts/Hilda-Regular.ttf")
      format("truetype");
  /* Safari, Android, iOS */
}
