.pwa_footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: white;
    background-color:#242424;
    font-size: 0.95em;
    padding: 0.25em;
    
    font-family: "myHelda", Fallback, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.12px;
    color: #ffffff;
  }