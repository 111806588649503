 .badge-light{
    background-color: #e0e0e0 !important;
    color: #242424 !important;
    font-size: 100% !important;
    margin-left: 0.5em !important;
  }
 
  .icon-invert {
    width: .95em;
    height: 1em;
    margin-left: 0.5em;
    object-fit: contain;
    filter: invert(100%);
  }
  
  .iconTile {
    width: .95em;
    height: 1em;
    margin-left: 0.5em;
    object-fit: contain;
  }

  .dark .card{

    padding : 12px !important;
  }
  .dark .card .content{

    padding-top: 0 !important;
  }

  .light .card{

    padding : 12px !important;
  }
  .light .card .content{

    padding-top: 0 !important;
  }